<template>
	<div class="com_layer_normal layer_terms" style="position: absolute; z-index: 9999" @agree="agree">
		<div class="lay_header">
			<h1>{{ info.termsName }}</h1>
			<a class="btn_close" title="닫기" @click="close()"><span class="ic_set ic_close"></span></a>
		</div>
		<!--content-->
		<div class="lay_body">
			<div class="wrap_scroll" v-html="info.termsDetails[0].bodyTemplate"></div>
		</div>
		<!--//content end-->
		<div class="lay_bottom">
			<div class="wrap_function">
				<button class="major" @click="agree()">확인</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['info'],
	methods: {
		async agree() {
			await this.$store.commit('setAgreeTerm', true);
			await this.close();
		},
		async close() {
			this.$emit('close');
		},
	},
};
</script>

<style></style>
