<template>
	<Fragment>
		<div class="wrap_row">
			<div class="form_data">
				<h2 v-if="erpSyncStatus && !containsNewAccountAddon">고객 정보가 모두 확인되어 있습니다.</h2>
				<template v-if="!erpSyncStatus">
					<div
						class="row"
						:class="[
							companyInfoValidClass.corporateName == 'error' || companyInfoValidClass.corporateName == 'empty'
								? 'error'
								: companyInfoValidClass.corporateName == 'valid'
								? 'valid'
								: '',
						]"
					>
						<!--@type : normal-->
						<div class="com_ipt_floating">
							<input
								:disabled="origin.corporateName"
								class="asterisk"
								type="text"
								placeholder="."
								v-model="companyInfo.corporateName"
								@input="
									[
										checkInputLength($event, 2, 64)
											? (companyInfoValidClass.corporateName = 'valid')
											: (companyInfoValidClass.corporateName = 'error'),
									]
								"
							/>
							<label for="target">상호명</label>
						</div>
						<span class="com_help">
							<span class="ic_set ic_info">
								<span class="tool_tip right">
									사업자등록증에 표기되어 있는 상호명을 입력해주세요.<br />
									<i class="tail_right"></i
								></span>
							</span>
						</span>
						<span v-if="!origin.corporateName && companyInfoValidClass.corporateName == 'error'" class="sys_msg"
							>상호명은 2자 이상, 64자 이하를 입력해야 합니다.
						</span>
						<span v-if="!origin.corporateName && companyInfoValidClass.corporateName == 'empty'" class="sys_msg"
							>상호명은 필수 입력 항목입니다.
						</span>
					</div>

					<div
						class="row"
						:class="[
							companyInfoValidClass.ceoName == 'error' || companyInfoValidClass.ceoName == 'empty'
								? 'error'
								: companyInfoValidClass.ceoName == 'valid'
								? 'valid'
								: '',
						]"
					>
						<!--@type : normal-->
						<div class="com_ipt_floating">
							<input
								:disabled="origin.ceoName"
								class="asterisk"
								type="text"
								placeholder="."
								v-model="companyInfo.ceoName"
								@input="
									[
										checkInputLength($event, 2, 64)
											? (companyInfoValidClass.ceoName = 'valid')
											: (companyInfoValidClass.ceoName = 'error'),
									]
								"
							/>
							<label for="target">대표자명</label>
						</div>
						<span v-if="!origin.ceoName && companyInfoValidClass.ceoName == 'error'" class="sys_msg"
							>대표자명은 2자 이상, 64자 이하를 입력해야 합니다.</span
						>
						<span v-if="!origin.ceoName && companyInfoValidClass.ceoName == 'empty'" class="sys_msg"
							>대표자명은 필수 입력 항목입니다.</span
						>
					</div>

					<div
						class="row"
						:class="[
							companyInfoValidClass.businessLicenseNum == 'error' || companyInfoValidClass.businessLicenseNum == 'empty'
								? 'error'
								: companyInfoValidClass.businessLicenseNum == 'valid'
								? 'valid'
								: '',
							isBusinessActive ? activeClass : '',
						]"
					>
						<!--@type : normal / button-->
						<!--button layout-->
						<div class="cell cell_confirm_registration">
							<div class="cell_grid">
								<div class="com_ipt_floating">
									<input
										:disabled="origin.businessLicenseNum"
										class="asterisk"
										type="text"
										placeholder="."
										v-model="companyInfo.businessLicenseNum"
										@keyup="
											[
												checkBusinessNumber(companyInfo.businessLicenseNum)
													? (companyInfoValidClass.businessLicenseNum = 'valid')
													: (companyInfoValidClass.businessLicenseNum = 'error'),
											]
										"
										@keydown="changeBussinessInfo"
									/>
									<label for="target">사업자등록번호</label>
								</div>
							</div>
							<div class="cell_grid" @mouseover="isBusinessActive = true" @mouseleave="isBusinessActive = false">
								<LicenseConfirm
									v-model="businessInfoConfirm"
									:businessNum="companyInfo.businessLicenseNum"
									:disabled="origin.businessLicenseNum"
								></LicenseConfirm>
							</div>
						</div>
						<span
							v-if="!origin.businessLicenseNum && companyInfoValidClass.businessLicenseNum == 'valid'"
							class="sys_msg"
							>확인되었습니다.
						</span>
						<span
							v-if="!origin.businessLicenseNum && companyInfoValidClass.businessLicenseNum == 'error'"
							class="sys_msg"
							>번호 형식이 올바르지 않습니다. (-, +, 0~9)</span
						>
						<span
							v-if="!origin.businessLicenseNum && companyInfoValidClass.businessLicenseNum == 'empty'"
							class="sys_msg"
							>사업자등록번호는 필수 입력 항목입니다.</span
						>
					</div>

					<div class="row" :class="companyInfoValidClass.directPhone" v-show="false">
						<!--@type : normal-->
						<div class="com_ipt_floating">
							<input
								:disabled="origin.repNumber"
								class=""
								type="text"
								placeholder="."
								value="01099990000"
								v-model="companyInfo.directPhone"
								@keyup="
									[
										checkTelFormat(companyInfo.directPhone)
											? (companyInfoValidClass.directPhone = 'valid')
											: (companyInfoValidClass.directPhone = 'error'),
									]
								"
							/>
							<label for="target">대표전화</label>
						</div>
						<span v-if="!origin.directPhone && companyInfoValidClass.directPhone == 'error'" class="sys_msg"
							>번호형식이 올바르지 않습니다. (-, +, 0~9)</span
						>
					</div>
					<div
						class="row"
						:class="[
							companyInfoValidClass.companyAddress == 'error' || companyInfoValidClass.companyAddress == 'empty'
								? 'error'
								: companyInfoValidClass.companyAddress == 'valid'
								? 'valid'
								: '',
						]"
					>
						<!--@type : normal-->
						<div class="com_ipt_floating">
							<input
								:disabled="origin.companyAddress"
								class="asterisk"
								type="text"
								placeholder="."
								v-model="companyInfo.companyAddress"
								@input="
									[
										checkInputLength($event, 0, 5000)
											? (companyInfoValidClass.companyAddress = 'valid')
											: (companyInfoValidClass.companyAddress = 'error'),
									]
								"
							/>
							<label for="target">사업장 주소</label>
						</div>
						<div class="com_ipt_floating"></div>
						<span v-if="!origin.companyAddress && companyInfoValidClass.companyAddress == 'error'" class="sys_msg"
							>사업장 주소는 필수입력 항목입니다.</span
						>
						<span v-if="!origin.companyAddress && companyInfoValidClass.companyAddress == 'empty'" class="sys_msg"
							>사업장 주소는 필수입력 항목입니다.</span
						>
					</div>
					<!--@type : normal / dropdown-->
					<div class="com_slt floating">
						<select
							:disabled="origin.industryCategory"
							class="slt_normal asterisk"
							v-model="companyInfo.industrySector"
						>
							<option value="" selected="" disabled="">선택하세요.</option>
							<option v-for="item in $constants.INDUSTRY_CATEGORY" :key="item.id" :value="item.name">
								{{ item.name }}
							</option>
						</select>
						<label for="target">산업분류</label>
					</div>
					<div
						class="row"
						:class="[
							companyInfoValidClass.businessCondition == 'error' || companyInfoValidClass.businessCondition == 'empty'
								? 'error'
								: companyInfoValidClass.businessCondition == 'valid'
								? 'valid'
								: '',
						]"
					>
						<!--@type : normal-->
						<div class="com_ipt_floating">
							<input
								:disabled="origin.businessCondition"
								class="asterisk"
								type="text"
								placeholder="."
								v-model="companyInfo.businessCondition"
								@input="
									[
										checkInputLength($event, 2, 64)
											? (companyInfoValidClass.businessCondition = 'valid')
											: (companyInfoValidClass.businessCondition = 'error'),
									]
								"
							/>
							<label for="target">업태</label>
						</div>
						<span v-if="!origin.businessCondition && companyInfoValidClass.businessCondition == 'empty'" class="sys_msg"
							>업태는 필수 입력 항목입니다.
						</span>
						<span v-if="!origin.businessCondition && companyInfoValidClass.businessCondition == 'error'" class="sys_msg"
							>업태는 2자 이상, 64자 이하를 입력해야 합니다.
						</span>
					</div>
					<div class="row" :class="companyInfoValidClass.businessType">
						<!--@type : normal-->
						<div class="com_ipt_floating">
							<input
								:disabled="origin.businessType"
								class="asterisk"
								type="text"
								placeholder="."
								v-model="companyInfo.businessType"
								@input="
									[
										checkInputLength($event, 2, 64)
											? (companyInfoValidClass.businessType = 'valid')
											: (companyInfoValidClass.businessType = 'error'),
									]
								"
							/>
							<label for="target">종목</label>
						</div>
						<span v-if="!origin.businessType && companyInfoValidClass.businessType == 'empty'" class="sys_msg">
							종목은 필수 입력 항목입니다.
						</span>
						<span v-if="!origin.businessType && companyInfoValidClass.businessType == 'error'" class="sys_msg"
							>종목은 2자 이상, 64자 이하를 입력해야 합니다.
						</span>
					</div>
					<div class="row" v-if="origin.businessLicenses.length == 0">
						<AttachComponent :gatherFlag="saveFlag"></AttachComponent>
					</div>

					<div class="row valid" v-if="origin.businessLicenses.length > 0">
						<!--@type : normal-->
						<div class="com_ipt_floating">
							<input
								:disabled="true"
								class="asterisk"
								type="text"
								placeholder="."
								:value="origin.businessLicenses[0].originFilename"
							/>
							<label for="target">사업자등록증</label>
						</div>
					</div>
					<div
						class="row"
						:class="[
							companyInfoValidClass.issuedBillName == 'error' || companyInfoValidClass.issuedBillName == 'empty'
								? 'error'
								: companyInfoValidClass.issuedBillName == 'valid'
								? 'valid'
								: '',
						]"
					>
						<!--@type : normal-->
						<div class="com_ipt_floating">
							<input
								:disabled="origin.issuedBillName"
								class="asterisk"
								type="text"
								placeholder="."
								v-model="companyInfo.issuedBillName"
								@input="
									[
										checkInputLength($event, 2, 64)
											? (companyInfoValidClass.issuedBillName = 'valid')
											: (companyInfoValidClass.issuedBillName = 'error'),
									]
								"
							/>
							<label for="target">세금계산서 담당자명</label>
						</div>
						<span v-if="!origin.issuedBillName && companyInfoValidClass.issuedBillName == 'error'" class="sys_msg"
							>세금계산서 담당자명은 2자 이상, 64자 이하를 입력해야 합니다.
						</span>
						<span v-if="!origin.issuedBillName && companyInfoValidClass.issuedBillName == 'empty'" class="sys_msg"
							>세금계산서 담당자명은 필수 입력 항목입니다.
						</span>
						<span class="com_help">
							<span class="ic_set ic_info">
								<span class="tool_tip right">
									세금계산서 담당자 이메일로 세금계산서 관련 메일이 발송됩니다.<br />
									<i class="tail_right"></i
								></span>
							</span>
						</span>
					</div>
					<div
						class="row"
						:class="[
							companyInfoValidClass.issuedBillEmail == 'error' || companyInfoValidClass.issuedBillEmail == 'empty'
								? 'error'
								: companyInfoValidClass.issuedBillEmail == 'valid'
								? 'valid'
								: '',
						]"
					>
						<!--@type : normal-->
						<div class="com_ipt_floating">
							<input
								:disabled="origin.issuedBillEmail"
								class="asterisk"
								type="text"
								placeholder="."
								v-model="companyInfo.issuedBillEmail"
								@keyup="
									[
										checkEmail(companyInfo.issuedBillEmail)
											? (companyInfoValidClass.issuedBillEmail = 'valid')
											: (companyInfoValidClass.issuedBillEmail = 'error'),
									]
								"
							/>
							<label for="target">세금계산서 담당자 이메일</label>
						</div>
						<span v-if="!origin.issuedBillEmail && companyInfoValidClass.issuedBillEmail == 'error'" class="sys_msg"
							>세금계산서 담당자 이메일 형식이 올바르지 않습니다.
						</span>
						<span v-if="!origin.issuedBillEmail && companyInfoValidClass.issuedBillEmail == 'empty'" class="sys_msg"
							>세금계산서 담당자 이메일은 필수 입력 항목입니다.
						</span>
					</div>
				</template>
				<template v-for="(item, index) in newlyAddedAddonChannel">
					<div
						v-if="
							item.baseInfo.integrationCode === 'ACCOUNT' && $store.getters.getUsingMembership.serviceType === 'SAAS'
						"
						class="row active"
						:class="[
							companyInfoValidClass.addonItem[index].masterId == 'error' ||
							companyInfoValidClass.addonItem[index].masterId == 'empty' ||
							companyInfoValidClass.addonItem[index].masterId == 'needConfirm'
								? 'error'
								: companyInfoValidClass.addonItem[index].masterId == 'valid'
								? 'valid'
								: '',
						]"
						:key="'row' + index"
					>
						<!--@type : normal-->
						<div class="cell cell_confirm_registration">
							<div class="cell_grid">
								<div class="com_ipt_floating">
									<input class="asterisk" type="text" placeholder="." v-model="companyInfo.addonItem[index].masterId" />
									<label for="target">{{ item.baseInfo.name }} 관리자 ID</label>
								</div>
							</div>
							<div class="cell_grid">
								<button class="form_large" @click="masterIdConfirm(index)">
									<span class="txt">관리자 ID 확인</span>
								</button>
							</div>
						</div>
						<span class="com_help">
							<span class="ic_set ic_info">
								<span class="tool_tip right">
									관리자 페이지에 접근할 수 있는 사용자 계정입니다. <br />
									(관리자페이지에서 수정 가능합니다.)
									<i class="tail_right"></i
								></span>
							</span>
						</span>
						<span v-if="companyInfoValidClass.addonItem[index].masterId == 'valid'" class="sys_msg"
							>사용할 수 있는 관리자ID 입니다.</span
						>
						<span v-if="companyInfoValidClass.addonItem[index].masterId == 'error'" class="sys_msg"
							>관리자 ID는 3자 이상, 64자 이하의 영문(소문자), 숫자, -, _, . 만 입력해야 합니다.
						</span>
						<span v-if="companyInfoValidClass.addonItem[index].masterId == 'empty'" class="sys_msg"
							>관리자 ID는 필수 입력 항목입니다.
						</span>
						<span v-if="companyInfoValidClass.addonItem[index].masterId == 'needConfirm'" class="sys_msg"
							>입력하신 관리자ID에 대한 [확인]이 필요합니다.
						</span>
					</div>
					<div
						v-if="false"
						class="row"
						:class="[
							companyInfoValidClass.addonItem[index].url == 'error' ||
							companyInfoValidClass.addonItem[index].url == 'empty' ||
							companyInfoValidClass.addonItem[index].url == 'needConfirm'
								? 'error'
								: companyInfoValidClass.addonItem[index].url == 'valid'
								? 'valid'
								: companyInfoValidClass.addonItem[index].url == 'active'
								? 'valid active'
								: '',
						]"
						:key="'url' + index"
					>
						<!--@type : normal / button / ok-->
						<!--button layout-->
						<div class="cell cell_confirm_ok">
							<!--@확인 버튼 노출-->
							<div class="cell_grid">
								<div class="com_ipt_floating">
									<input
										class="asterisk"
										type="text"
										placeholder="."
										value="ddedde.daouoffice.co.kr"
										v-model="companyInfo.addonItem[index].url"
									/>
									<label for="target">{{ item.baseInfo.name }} 접속 URL</label>
								</div>
							</div>
							<div class="cell_grid">
								<button
									class="form_large"
									@click="
										[
											checkUrl(companyInfo.addonItem[index].url)
												? (companyInfoValidClass.addonItem[index].url = 'valid')
												: (companyInfoValidClass.addonItem[index].url = 'error'),
										]
									"
									@mouseover="
										[
											companyInfoValidClass.addonItem[index].url == ''
												? (companyInfoValidClass.addonItem[index].url = 'active')
												: (companyInfoValidClass.addonItem[index].url = companyInfoValidClass.addonItem[index].url),
										]
									"
									@mouseleave="
										[
											companyInfoValidClass.addonItem[index].url == 'active'
												? (companyInfoValidClass.addonItem[index].url = '')
												: (companyInfoValidClass.addonItem[index].url = companyInfoValidClass.addonItem[index].url),
										]
									"
								>
									<span class="txt">확인</span>
								</button>
							</div>
							<span class="com_help">
								<span class="ic_set ic_info">
									<span class="tool_tip right">
										다우오피스에 접속할 수 있는 회사만의 고유 주소입니다. ex) test.mydomain.co.kr
										<i class="tail_right"></i
									></span>
								</span>
							</span>
						</div>
						<span v-if="companyInfoValidClass.addonItem[index].url == 'valid'" class="sys_msg"
							>사용할 수 있는 주소입니다.</span
						>
						<span v-if="companyInfoValidClass.addonItem[index].url == 'error'" class="sys_msg"
							>접속 URL은 1자 이상, 64자 이하의 영문, 숫자, -, _ 만 입력해야 합니다.</span
						>
						<span v-if="companyInfoValidClass.addonItem[index].url == 'empty'" class="sys_msg"
							>접속 URL은 필수 입력 항목입니다.</span
						><span v-if="companyInfoValidClass.addonItem[index].url == 'needConfirm'" class="sys_msg"
							>입력하신 접속 URL에 대한 [확인]이 필요합니다.
						</span>
					</div>
				</template>
			</div>
		</div>
	</Fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';

import ToastComponent from '@/components/code/modal/ToastComponent.vue';
import AttachComponent from '@/components/attach/Attach';
import { validation } from '@/components/apply/mixinsValidation.js';
import LicenseConfirm from '@/components/businessLicense/LicenseConfirm';
export default {
	mixins: [validation],
	props: {
		saveFlag: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		AttachComponent,
		Fragment,
		LicenseConfirm,
	},
	data() {
		const customer = this.$store.getters.getUsingMembership.customer;
		// 사업자 등록번호
		const origin = {
			corporateName: null,
			ceoName: null,
			businessLicenseNum: null,
			companyAddress: null,
			repNumber: null, // 대표번호
			companyZipCode: null,
			issuedBillName: null,
			issuedBillEmail: null,
			businessLicenses: [],
			industryCategory: null,
			businessCondition: null,
			businessType: null,
		};
		origin.corporateName = customer.companyInfo.corporateName ? customer.companyInfo.corporateName : null; // 상호
		origin.ceoName = customer.companyInfo.ceoName ? customer.companyInfo.ceoName : null; // 대표자명
		origin.businessLicenseNum = customer.companyInfo.businessLicenseNum
			? customer.companyInfo.businessLicenseNum
			: null; // 사업자등록번호

		origin.companyAddress = customer.companyInfo.companyAddress ? customer.companyInfo.companyAddress : null; // 사업장 주소
		origin.repNumber = customer.companyInfo.repNumber ? customer.companyInfo.repNumber : null;
		origin.companyZipCode = customer.companyInfo.zipCode ? customer.companyInfo.zipCode : null; // 우편번호
		origin.industryCategory = customer.companyInfo.industryCategory ? customer.companyInfo.industryCategory : null; // 산업분류
		origin.businessCondition = customer.companyInfo.businessCondition ? customer.companyInfo.businessCondition : null; // 업태
		origin.businessType = customer.companyInfo.businessType ? customer.companyInfo.businessType : null; // 종목

		origin.issuedBillName = customer.companyInfo.taxInvoiceManager.taxInvoiceManagerName
			? customer.companyInfo.taxInvoiceManager.taxInvoiceManagerName
			: null; // 세금계산서 담당자 이름
		origin.issuedBillEmail = customer.companyInfo.taxInvoiceManager.taxInvoiceEmail
			? customer.companyInfo.taxInvoiceManager.taxInvoiceEmail
			: null; // 세금계산서 담당자 이메일 */

		origin.businessLicenses = customer.companyInfo.businessLicenses; // 사업자 등록증
		if (origin.businessLicenses.length > 0) {
			this.$store.commit('setBusinessRegistration', origin.businessLicenses);
		}
		// 신규 추가 부가서비스
		var newlyAddedAddonChannel = this.$store.getters.getAddonChannel.filter((obj) => obj.baseInfo.usingCount == 0);
		var addonItemData = [];
		var addonItemValid = [];
		// const usingMasterId = this.$store.getters.getUsingMembership.baseChannelInfoModel.baseChannelBaseInfoModel
		// 	.adminLoginId;
		if (newlyAddedAddonChannel.length > 0) {
			newlyAddedAddonChannel.forEach((data) => {
				addonItemData.push({
					code: data.baseInfo.code,
					name: data.baseInfo.name,
					integrationCode: data.baseInfo.integrationCode,
					masterId: '',
					url: '',
				});
				addonItemValid.push({
					name: data.baseInfo.name,
					integrationCode: data.baseInfo.integrationCode,
					masterId: '',
					url: '',
				});
			});
		}

		return {
			toastOpen: false,
			newlyAddedAddonChannel,
			erpSyncStatus: false,
			origin,
			companyInfo: {
				corporateName: '', // 필수
				businessLicenseNum: '', // 필수
				ceoName: '', // 필수
				directPhone: '', // 필수
				industrySector: '',
				companyZipCode: '',
				companyAddress: '', // 필수
				businessCondition: '',
				businessType: '',
				issuedBillName: '', // 필수
				issuedBillEmail: '', // 필수
				addonItem: addonItemData, // 필수
				confirm: true,
			},
			companyInfoValidClass: {
				erpSyncStatus: false,
				corporateName: '',
				businessLicenseNum: '',
				ceoName: '',
				directPhone: '',
				industrySector: '',
				companyZipCode: 'valid',
				companyAddress: '',
				businessCondition: '',
				businessType: '',
				issuedBillName: '',
				issuedBillEmail: '',
				addonItem: addonItemValid,
			},
			businessInfoConfirm: false,
			activeClass: 'active',
			isBusinessActive: false,
		};
	},
	created() {
		this.getCustomer();
		const origin = this.origin;

		if (origin.corporateName) {
			this.companyInfo.corporateName = origin.corporateName;
			this.companyInfoValidClass.corporateName = 'valid';
		}

		if (origin.businessLicenseNum) {
			this.companyInfo.businessLicenseNum = origin.businessLicenseNum;
			this.companyInfoValidClass.businessLicenseNum = 'valid';
		}

		if (origin.businessLicenses) {
			this.companyInfo.businessLicenses = origin.businessLicenses;
		}

		if (origin.ceoName) {
			this.companyInfo.ceoName = origin.ceoName;
			this.companyInfoValidClass.ceoName = 'valid';
		}
		if (origin.repNumber) {
			this.companyInfo.directPhone = origin.repNumber;
			this.companyInfoValidClass.directPhone = 'valid';
		}
		if (origin.industryCategory) {
			this.companyInfo.industrySector = origin.industryCategory;
		}
		if (origin.companyZipCode) {
			this.companyInfo.companyZipCode = origin.companyZipCode;
			this.companyInfoValidClass.companyZipCode = 'valid';
		}
		if (origin.companyAddress) {
			this.companyInfo.companyAddress = origin.companyAddress;
			this.companyInfoValidClass.companyAddress = 'valid';
		}
		if (origin.businessCondition) {
			this.companyInfo.businessCondition = origin.businessCondition;
			this.companyInfoValidClass.businessCondition = 'valid';
		}
		if (origin.businessType) {
			this.companyInfo.businessType = origin.businessType;
			this.companyInfoValidClass.businessType = 'valid';
		}
		if (origin.issuedBillName) {
			this.companyInfo.issuedBillName = origin.issuedBillName;
			this.companyInfoValidClass.issuedBillName = 'valid';
		}
		if (origin.issuedBillEmail) {
			this.companyInfo.issuedBillEmail = origin.issuedBillEmail;
			this.companyInfoValidClass.issuedBillEmail = 'valid';
		}
	},
	watch: {
		async saveFlag() {
			if (this.saveFlag) {
				await this.checkValidInfo();
				await this.saveUserInfo();
			}
		},
		businessInfoConfirm() {
			this.companyInfo.confirm = this.businessInfoConfirm;
			if (this.businessInfoConfirm) {
				this.companyInfoValidClass.businessLicenseNum = 'valid';
			} else {
				this.companyInfoValidClass.businessLicenseNum = 'error';
			}
		},
	},
	computed: {
		containsNewAccountAddon() {
			return this.newlyAddedAddonChannel.findIndex((newAddon) => newAddon.baseInfo.integrationCode === 'ACCOUNT') > -1;
		},
	},
	methods: {
		async masterIdConfirm(idx) {
			try {
				let validClass = 'valid';
				const baseChannelInfo = this.$store.getters.getUsingMembership.representativeGroupware;
				const masterId = this.companyInfo.addonItem[idx].masterId;
				const { data } = await this.$store.dispatch('CONFIRM_ADMIN_ID', {
					email: `${masterId}@${baseChannelInfo.domain}`,
				});

				if (!data) {
					validClass = 'needConfirm';
				}

				this.companyInfoValidClass.addonItem[idx].masterId = validClass;
			} catch (e) {
				console.error(e);
				this.companyInfoValidClass.addonItem[idx].masterId = 'needConfirm';
			}
		},
		changeBussinessInfo() {
			this.companyInfo.confirm = false;
			this.businessInfoConfirm = false;
		},
		async getCustomer() {
			const customerId = await this.$store.getters.getUsingMembership.customer.id;
			const { data } = await this.$store.dispatch('GETCUSTOMER', customerId);
			this.erpSyncStatus = data.erpSyncStatus ? (data.erpSyncStatus == 'DONE' ? true : false) : false;

			this.companyInfoValidClass.erpSyncStatus = this.erpSyncStatus;
			return data;
		},
		async checkValidInfo() {
			for (const idx in Object.keys(this.companyInfo)) {
				const key = Object.keys(this.companyInfo)[idx];
				const value = this.companyInfo[key];
				if (key !== 'companyZipCode' && key !== 'addonItem') {
					if (!value || value.length == 0) {
						this.companyInfoValidClass[key] = 'empty';
					}
				}
			}

			if (this.companyInfo.addonItem.length > 0) {
				for (let i = 0; i < this.companyInfo.addonItem.length; i++) {
					const addon = this.companyInfo.addonItem[i];
					const addonValidClass = this.companyInfoValidClass.addonItem[i];

					if (addon.integrationCode !== 'ACCOUNT') {
						continue;
					}

					if (addon.masterId == '') {
						addonValidClass.masterId = 'empty';
					}

					if (addonValidClass.masterId == '' || addonValidClass.masterId == 'empty' || addon.masterId == '') {
						addonValidClass.masterId = 'needConfirm';
					}

					// 사용안함. (일단주석)
					// if (!addon.url || addon.url.length == 0) {
					// 	this.companyInfoValidClass.addonItem[i].url = 'empty';
					// }
					// if (
					// 	this.companyInfoValidClass.addonItem[i].url == '' ||
					// 	(this.companyInfoValidClass.addonItem[i].url == 'empty' &&
					// 		!(!addon.url || addon.url.length == 0))
					// ) {
					// 	this.companyInfoValidClass.addonItem[i].url = 'needConfirm';
					// }
				}
			}
			return;
		},
		checkUrl(data) {
			if (data.length < 1 || data.length > 64) {
				return false;
			}

			var url = data;
			var pattern = /[\Wㄱ-ㅎㅏ-ㅣ가-힣]/;

			if (pattern.test(url.replace(/[\\_.-]/g, ''))) {
				return false;
			}
			// 중복체크
			return true;
		},
		removeEmpty(val) {
			return val.replace(/\s+/, '').replace(/\s+$/g, '').replace(/\n/g, '').replace(/\r/g, '');
		},
		async saveUserInfo() {
			await this.$emit('setNewSiteInfo', {
				companyInfoValidClass: this.companyInfoValidClass,
				companyInfo: this.companyInfo,
			});
		},
		openToast(text) {
			this.$modal.show(
				ToastComponent,
				{
					toastText: text,
				},
				{
					width: '800px',
					height: '80px',
					draggable: true,
				},
				{
					closed: this.closeModal,
				},
			);
		},
	},
};
</script>

<style></style>
