import PayRequestLayer from '@/components/payment/PayRequestLayer.vue';
export const payment = {
	// 결제 관련 methods
	methods: {
		async openPayRequestLayer(payMethodInfo) {
			// 결제 레이어 open
			await localStorage.setItem('payMethodInfo', JSON.stringify(payMethodInfo));
			await this.$store.commit('setPayMethodInfo', payMethodInfo);

			await this.$modal.show(
				PayRequestLayer,
				{
					payMethodInfo: payMethodInfo,
				},
				{
					width: '768px',
					height: 'auto',
					scrollable: true,
					draggable: true,
				},
				{
					closed: this.close,
				},
			);
		},
		async close() {},
		async goNextPage(path) {
			// 다음 페이지로 이동
			if (this.$route.path !== path) this.$router.push(path);
		},
		async goPayment(payment, nextPath) {
			// 결제 창 open 후, 다음 페이지로 이동
			await this.openPayRequestLayer(payment);
			await window.addEventListener(
				'message',
				(event) => {
					if (event.data === 'success') {
						this.goNextPage(nextPath);
					}
				},
				false,
			);
		},
		checkAutoExtension(payMethodInfo) {
			if (!payMethodInfo.cardNo) {
				this.$ms.toast.show('카드번호를 입력해주세요');
				return false;
			}
			if (!payMethodInfo.expiredDt.year || !payMethodInfo.expiredDt.month) {
				this.$ms.toast.show('카드 유효기간(MM/YY)을 입력해주세요.');
				return false;
			}
			if (!payMethodInfo.cardPassword) {
				this.$ms.toast.show('카드비밀번호 앞 2자리를 입력해주세요.');
				return false;
			}
			if (!payMethodInfo.cardAuth) {
				this.$ms.toast.show('생년월일 혹은 사업자 번호를 입력해주세요.');
				return false;
			}
			return true;
		},
		async setAutoExtension(payMethodInfo, orderId, customerId) {
			// 자동결제 api 호출
			const params = {
				payMethod: 'SSL',
				cpid: window.__env.code.AUTO_CP_ID,
				autoCpid: window.__env.code.AUTO_CP_ID,
				productType: 1,
				billType: 14,
				taxFreecd: '00',
				amount: payMethodInfo.amount, //결제금액
				productName: payMethodInfo.orderSummary,
				orderNo: 'DAOU_SAAS_' + orderId,
				userId: 'hsim',
				userName: payMethodInfo.corporateName, // 상호명
				autoMonths: 99,
				cardNo: payMethodInfo.cardNo,
				expiredt: '20'.concat(payMethodInfo.expiredDt.year, payMethodInfo.expiredDt.month),
				cardAuth: payMethodInfo.cardAuth,
				cardPassword: payMethodInfo.cardPassword,
				customerId: customerId,
				lastCardNo: payMethodInfo.cardNo.substr(12, 4),
			};

			const res = await this.$store.dispatch('SETAUTOEXTENSION', params);
			if (res.errorCode !== '0000') {
				const errorMessage = res.errorCode + ':' + res.errorMessage;
				this.$ms.toast.show(errorMessage);
				return false;
			}

			return true;
		},
	},
};
