<template>
	<div class="com_layer_normal layer_terms" style="position: absolute; z-index: 9999px; height: 600px; width: 600px">
		<div class="lay_header">
			<h1>경리회계 이용약관</h1>
			<a class="btn_close" title="닫기" @click="close()"><span class="ic_set ic_close"></span></a>
		</div>
		<!--content-->
		<div class="lay_body">
			<table class="account_terms">
				<thead>
					<tr>
						<th><strong>수집/이용 목적</strong></th>
						<th><strong>개인 정보 항목</strong></th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							서비스 이용에 따른 본인 식별/인증 절차, 서비스 가입 변경/처리, 서비스 제공 관련 안내, 문의 불만 처리
						</td>
						<td>회사명, 사업자번호, 담당자명, 직급, 연락처, 이메일</td>
					</tr>
					<tr>
						<td>다우오피스 서비스 제공, 다우오피스 경리회계 서비스 제공</td>
						<td>회사명, 사업자번호, 담당자명, 직급, 연락처, 이메일</td>
					</tr>
					<tr>
						<td>전자세금계산서 서비스 제공(이용고객에 한함)</td>
						<td>대표자명, 담당자명, 연락처, 아이디, 비밀번호, 이메일 주소, 전자세금계산서용 인증서 및 비밀번호</td>
					</tr>
					<tr>
						<td>거래처, 회계, 자동증빙의 스크래핑 서비스 제공 (이용고객에 한함)</td>
						<td>
							홈텍스 인증서 및 인증서 비밀번호, 여신금융협회 아이디, 여신금융협회 비밀번호, 카드사명, 카드종류(법인),
							카드번호, 카드사 아이디, 카드사 비밀번호, 은행명, 계좌번호, 은행 인증서 및 비밀번호, 통화코드, 계좌별칭
						</td>
					</tr>
					<tr>
						<td>국민은행 바로 ERP 통한 이체 및 조회 서비스 이용 (이용 고객에 한함)</td>
						<td>국민은행 인터넷 뱅킹 ID, 비밀번호</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="lay_bottom">
			<div class="wrap_function">
				<button class="major" @click="agree()">확인</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['info'],
	methods: {
		agree() {
			this.$store.commit('setAccountAgreeTerm', true);
			this.close();
		},
		close() {
			this.$emit('close');
		},
	},
};
</script>

<style scoped>
.account_terms th,
.account_terms td {
	text-align: left;
	border: 1px solid #d8d8d8;
	font-weight: 400;
	font-size: 14px;
}
.account_terms th {
	text-align: center;
}
</style>
