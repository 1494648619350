<template>
	<div class="com_layer_normal layer_organogram" style="width: 768px; height: 850px">
		<div class="lay_header">
			<h2>결제하기</h2>
			<a class="btn_close" title="닫기" v-on:click="closePopup()"><span class="ic_set ic_close"></span></a>
		</div>
		<div class="lay_body vue_component">
			<iframe :src="paymentUrl" width="700px" height="700px"></iframe>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		payMethodInfo: {},
	},
	data() {
		return {
			paymentUrl: '/kiwoom/popup',
		};
	},
	async created() {
		await window.addEventListener(
			'message',
			() => {
				this.closePopup();
			},
			false,
		);
	},
	methods: {
		async closePopup() {
			this.$emit('close');
		},
	},
};
</script>

<style scoped>
iframe html {
	margin-top: 50px !important;
}
</style>
