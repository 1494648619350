<template>
	<div class="form_data">
		<div class="wrap_terms">
			<div class="tit">
				<div class="com_ck bk">
					<label
						>서비스 약관 동의 (전체)<input type="checkbox" v-model="agreeAll" /><span class="checkmark"></span
					></label>
				</div>
			</div>
			<ul class="list_terms">
				<li v-for="(item, index) in termsModels" :key="index">
					<div class="com_ck bk">
						<label
							>{{ item.termsName }}
							<template v-if="item.required">(필수)</template>
							<template v-if="!item.required">(선택)</template>
							<input
								type="checkbox"
								:checked="item.approved"
								@click="
									item.approved = !item.approved;
									checkAllApproved();
								"
							/>
							<span class="checkmark"></span>
						</label>
						<span class="ic_set ic_arrow_r" v-on:click="openTermsLayer(item)"></span>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import termsTemplate from '@/components/apply/termsDetail.vue';
export default {
	props: {
		saveFlag: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		var termsModels = [];
		var agree = {};
		const baseChannels = this.$store.getters.getBaseChannel;
		const addonChannels = this.$store.getters.getAddonChannel;

		if (baseChannels.length > 0) {
			baseChannels.forEach((baseItem) => {
				if (baseItem.extension.length > 0 || baseItem.add.length > 0) {
					if (baseItem.baseInfo.termsSectionModel.useTerms) {
						if (baseItem.baseInfo.termsSectionModel.termsModels.length > 0) {
							baseItem.baseInfo.termsSectionModel.termsModels.forEach((object) => {
								if (termsModels.findIndex((obj) => obj.id == object.id) == -1) {
									termsModels.push(object);
								}
							});
						}
					}
				}

				if (baseItem.option.length > 0) {
					baseItem.option.forEach((option) => {
						if (option.termsSectionModel.useTerms) {
							if (option.termsSectionModel.termsModels.length > 0) {
								option.termsSectionModel.termsModels.forEach((object) => {
									if (termsModels.findIndex((obj) => obj.id == object.id) == -1) {
										termsModels.push(object);
									}
								});
							}
						}
					});
				}
			});
		}

		if (addonChannels.length > 0) {
			addonChannels.forEach((addonItem) => {
				if (addonItem.extension.length > 0 || addonItem.add.length > 0) {
					if (addonItem.baseInfo.termsSectionModel.useTerms) {
						if (addonItem.baseInfo.termsSectionModel.termsModels.length > 0) {
							addonItem.baseInfo.termsSectionModel.termsModels.forEach((object) => {
								if (termsModels.findIndex((obj) => obj.id == object.id) == -1) {
									termsModels.push(object);
								}
							});
						}
					}
				}

				if (addonItem.option.length > 0) {
					addonItem.option.forEach((option) => {
						if (option.termsSectionModel.useTerms) {
							if (option.termsSectionModel.termsModels.length > 0) {
								option.termsSectionModel.termsModels.forEach((object) => {
									if (termsModels.findIndex((obj) => obj.id == object.id) == -1) {
										termsModels.push(object);
									}
								});
							}
						}
					});
				}
			});
		}

		// 필수, 선택 별 정렬
		if (termsModels.length > 0) {
			termsModels.sort((a, b) => {
				return a.required && !b.required ? -1 : !a.required && b.required ? 1 : 0;
			});
		}
		if (termsModels.length > 0) {
			termsModels.forEach((obj) => {
				obj['approved'] = false;
			});
		}

		return {
			termsModels,
			agreeAll: false,
			agree,
			openLayer: false,
			openTerms: {}, // open할 term 내용
		};
	},
	async created() {
		await this.sendTermsCnt();
	},
	watch: {
		saveFlag() {
			if (this.saveFlag) {
				this.$emit('setTermsAgreement', this.termsModels);
			}
		},
		agreeAll() {
			if (this.termsModels.length > 0) {
				this.termsModels.forEach((obj) => {
					obj.approved = true;
				});
			}
		},
		termsModels: {
			deep: true,
			handler() {
				this.checkAllApproved();
			},
		},
	},
	methods: {
		async sendTermsCnt() {
			this.$emit('checkTermsCnt', this.termsModels.length);
		},
		checkAllApproved() {
			const filter = this.termsModels.filter((obj) => !obj.approved);

			if (filter.length == 0) {
				this.agreeAll = true;
			}
		},
		openTermsLayer(item) {
			this.openTerms = item;
			this.$store.commit('initAgreeTerm');
			this.$modal.show(
				termsTemplate,
				{
					info: item,
				},
				{
					width: '800px',
					height: '715px',
					draggable: true,
				},
				{
					closed: this.closeTermsLayer,
				},
			);
		},
		async closeTermsLayer() {
			const agreeTerm = this.$store.getters.getAgreeTerm;
			const temp = await this.cloneObj(this.termsModels);
			if (agreeTerm) {
				const idx = temp.findIndex((term) => term.id == this.openTerms.id);
				temp[idx].approved = true;
			}
			this.termsModels = temp;

			this.checkAllApproved();
		},
		async cloneObj(obj) {
			return JSON.parse(JSON.stringify(obj));
		},
	},
};
</script>

<style></style>
